@import '../shared.scss';

$JIVE_INPUT_BORDER: solid 1px $md-grey-400;
$JIVE_INPUT_BORDER_HOVER_WIDTH: 2px;
$JIVE_INPUT_LETTER_SPACING: 0.3px;
$JIVE_INPUT_BACKGROUND_COLOR: $md-white;
$JIVE_INPUT_PADDING: 0 16px;
$JIVE_INPUT_MARGIN: 3px;
$JIVE_INPUT_MARGIN_BOTTOM: 8px;
$JIVE_INPUT_TEXTAREA_HEIGHT: 140px;
$JIVE_INPUT_TEXTAREA_PADDING: 16px;

@mixin jive-input-header {
  font-family: var(--jive-font);
  font-size: $JIVE_FONT_SIZE;
  font-weight: 600;
  letter-spacing: $JIVE_INPUT_LETTER_SPACING;
  text-align: left;
  color: var(--jive-font-color);
  display: inline-block;
}

@mixin jive-input-placeholder {
  font-family: var(--jive-font);
  font-size: $JIVE_FONT_SIZE;
  letter-spacing: $JIVE_INPUT_LETTER_SPACING;
  text-align: left;
  color: $md-grey-500;
}

@mixin jive-input-hint {
  font-family: var(--jive-font);
  font-size: $JIVE_FONT_SIZE_SMALL;
  letter-spacing: $JIVE_INPUT_LETTER_SPACING;
  text-align: left;
  color: $md-grey-500;
}

@mixin jive-input {
  border: $JIVE_INPUT_BORDER;
  height: $JIVE_INPUT_COMPONENT_HEIGHT;
  width: 100%;
  box-sizing: border-box;
  border-radius: $JIVE_DEFAULT_BORDER_RADIUS;
  padding: $JIVE_INPUT_PADDING;
  margin: $JIVE_INPUT_MARGIN 0 $JIVE_INPUT_MARGIN_BOTTOM 0;
  color: var(--jive-font-color);
  resize: none;
  font-size: $JIVE_FONT_SIZE;

  @include input-placeholder {
    @include jive-input-placeholder;
  }

  &:focus {
    outline: none;
    border-width: $JIVE_INPUT_BORDER_HOVER_WIDTH;
  }

  &.small {
    height: $JIVE_INPUT_COMPONENT_HEIGHT_SMALL;
    font-size: $JIVE_FONT_SIZE_SMALL;
    @include input-placeholder {
      font-size: $JIVE_FONT_SIZE_SMALL;
    }
  }
  &.medium {
    height: $JIVE_INPUT_COMPONENT_HEIGHT_MEDIUM;
    font-size: $JIVE_FONT_SIZE_MEDIUM;
    @include input-placeholder {
      font-size: $JIVE_FONT_SIZE_MEDIUM;
    }
  }

  &[disabled] {
    background-color: inherit; // Fix for Firefox
    cursor: $JIVE_DISABLED_CURSOR;
    @include input-placeholder {
      color: $md-grey-600;
    }
  }
}
