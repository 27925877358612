/* You can add global styles to this file, and also import other style files */
@import 'app/style/variables';
@import 'app/style/app-theme';
@import 'app/style/common';
@import 'app/style/flag';
@import 'app/style/rebrand';

@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($jive-app-theme);
@include app-theme($jive-app-theme);

.app {
  font-family: $sans-serif;
  height: 100vh;
  margin: 0;
}

:root {
  --jive-font: #{$JIVE_FONT};
  --jive-font-color: #{$JIVE_FONT_COLOR};

  --jive-button-font-color: #{$JIVE_BUTTON_BASIC_FONT_COLOR};
  --jive-button-color: #{$JIVE_BUTTON_BASIC_COLOR};
  --jive-button-color-hover: #{$JIVE_BUTTON_BASIC_COLOR_HOVER};

  --jive-button-flat-color: #{$JIVE_BUTTON_FLAT_COLOR};
  --jive-button-flat-font-color: #{$JIVE_BUTTON_FLAT_FONT_COLOR};

  --jive-checkbox-radio-color: #{$JIVE_CHECKBOX_AND_RADIO_COLOR};
  --jive-checkbox-radio-border-color: #{$JIVE_CHECKBOX_AND_RADIO_BORDER_COLOR};
  --jive-checkbox-radio-focus-color: #{$JIVE_CHECKBOX_AND_RADIO_FOCUS_COLOR};
  --jive-checkbox-radio-focus-color-transparent: #{rgba($JIVE_CHECKBOX_AND_RADIO_FOCUS_COLOR, $JIVE_CHECKBOX_AND_RADIO_FOCUS_OPACITY)};

  --jive-secondary-border-color: #{$JIVE_BUTTON_SECONDARY_BORDER_COLOR};
  --jive-secondary-border-color-hover: #{$JIVE_BUTTON_SECONDARY_BORDER_COLOR_HOVER};
  --jive-secondary-color: #{$JIVE_BUTTON_SECONDARY_COLOR};
  --jive-secondary-color-hover: #{$JIVE_BUTTON_SECONDARY_COLOR_HOVER};
  --jive-secondary-font-color: #{$JIVE_BUTTON_SECONDARY_FONT_COLOR};
  --jive-secondary-font-color-hover: #{$JIVE_BUTTON_SECONDARY_FONT_COLOR_HOVER};

  --jive-warn-color: #{$JIVE_ERROR_COLOR};
  --jive-warn-color-hover: #{$JIVE_BUTTON_WARN_COLOR_HOVER};
  --jive-warn-font-color: #{$JIVE_BUTTON_WARN_FONT_COLOR};
  --jive-warn-font-color-hover: #{$JIVE_BUTTON_WARN_COLOR_HOVER};

  --jive-button-primary-font-color: #FFFFFF;
  --jive-button-primary-color: #000000;
  --jive-button-primary-color-hover: #2A3238;
  --jive-button-primary-color-pressed: #3D4852;
  --jive-button-primary-filter-pressed: none;
  --jive-button-font-color-disabled: rgba(0, 0, 0, 0.3);
  --jive-button-color-disabled: rgba(0, 0, 0, 0.1);
  --jive-button-filter-disabled: none;

  --jive-primary-color: #006AFF;
  --jive-primary-color-light: rgba(0, 106, 255, .54);
  --jive-primary-color-hover: #004FBD;
  --jive-primary-color-pressed: #003989;
  --jive-primary-font-color: #{$JIVE_BUTTON_PRIMARY_FONT_COLOR};
}
