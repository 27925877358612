@import 'material-colors/dist/colors.scss';

$ANGULARJS_CLASS_PREFIX: 'md';
$ANGULAR_CLASS_PREFIX: 'mat';
$JIVE_CLASS_PREFIX: 'jive';

// font related shared styles
$JIVE_FONT: "ProximaNova-Regular", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
$JIVE_FONT_COLOR: $md-grey-800;
$JIVE_FONT_SIZE: 16px;
$JIVE_FONT_SIZE_MEDIUM: 14px;
$JIVE_FONT_SIZE_SMALL: 12px;

$JIVE_PRIMARY_COLOR: $md-light-blue-a700;

// generic shared styles that work across broad parts of the app
$JIVE_NAVBAR_ICON_HEIGHT: 34px;
$JIVE_DEFAULT_BORDER_RADIUS: 3px;
$JIVE_ERROR_COLOR: $md-red-500;
$JIVE_DISABLED_OPACITY_FILTER: opacity(50%);
$JIVE_DISABLED_CURSOR: not-allowed;
$JIVE_DISABLED_OPACITY: .5;

// buttons default and hover colors
$JIVE_BUTTON_HOVER_DARKNESS: 10%;

$JIVE_BUTTON_BASIC_COLOR: $md-grey-200;
$JIVE_BUTTON_BASIC_COLOR_HOVER: darken($JIVE_BUTTON_BASIC_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);
$JIVE_BUTTON_BASIC_FONT_COLOR: $md-grey-600;

$JIVE_BUTTON_SECONDARY_BORDER_COLOR: $JIVE_PRIMARY_COLOR;
$JIVE_BUTTON_SECONDARY_BORDER_COLOR_HOVER: darken($JIVE_BUTTON_SECONDARY_BORDER_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);
$JIVE_BUTTON_SECONDARY_COLOR: $md-white;
$JIVE_BUTTON_SECONDARY_COLOR_HOVER: darken($JIVE_BUTTON_SECONDARY_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);
$JIVE_BUTTON_SECONDARY_FONT_COLOR: $JIVE_PRIMARY_COLOR;
$JIVE_BUTTON_SECONDARY_FONT_COLOR_HOVER: darken($JIVE_BUTTON_SECONDARY_FONT_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);

$JIVE_BUTTON_PRIMARY_COLOR: var(--jive-primary-color, $JIVE_PRIMARY_COLOR);
$JIVE_BUTTON_PRIMARY_COLOR_HOVER: darken($JIVE_PRIMARY_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);
$JIVE_BUTTON_PRIMARY_FONT_COLOR: $md-white;

$JIVE_BUTTON_FLAT_COLOR: transparent;
$JIVE_BUTTON_FLAT_FONT_COLOR: $JIVE_PRIMARY_COLOR;

$JIVE_BUTTON_WARN_FONT_COLOR: $md-white;
$JIVE_BUTTON_WARN_COLOR_HOVER: darken($JIVE_ERROR_COLOR, $JIVE_BUTTON_HOVER_DARKNESS);

// drop down here is referring to custom built drop downs, such as country selector
// language selector etc..
$JIVE_DROPDOWN_LIST_BOX_SHADOW: 0px 2px 9px 0px $md-grey-300;
$JIVE_DROPDOWN_LIST_ROW_HEIGHT: 32px;
$JIVE_DROPDOWN_LIST_STYLE_TYPE: none;
$JIVE_DROPDOWN_LIST_ROW_HIGHLIGHT_COLOR: $md-grey-100;

// these are for input components like select, phone number format, buttons, inputs
$JIVE_INPUT_COMPONENT_HEIGHT: 40px;
$JIVE_INPUT_COMPONENT_HEIGHT_MEDIUM: 32px;
$JIVE_INPUT_COMPONENT_HEIGHT_SMALL: 24px;

$JIVE_CHECKBOX_AND_RADIO_COLOR: $JIVE_PRIMARY_COLOR;
$JIVE_CHECKBOX_AND_RADIO_MARGIN: 4px;
$JIVE_CHECKBOX_AND_RADIO_FOCUS_COLOR: $md-grey-400;
$JIVE_CHECKBOX_AND_RADIO_HEIGHT_DEFAULT: 24px;
$JIVE_CHECKBOX_AND_RADIO_WIDTH_DEFAULT: 24px;
$JIVE_CHECKBOX_AND_RADIO_HEIGHT_SMALL: 16px;
$JIVE_CHECKBOX_AND_RADIO_WIDTH_SMALL: 16px;
$JIVE_CHECKBOX_AND_RADIO_FOCUS_OPACITY: .3;
$JIVE_CHECKBOX_AND_RADIO_BORDER_WIDTH: 1px;
$JIVE_CHECKBOX_AND_RADIO_BORDER_COLOR: $md-grey-500;
$JIVE_CHECKBOX_AND_RADIO_FONT_SIZE: 14px;

// helpers
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin checkbox-and-radio-button-focus-state {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  background-color: var(--jive-checkbox-radio-focus-color);
  opacity: $JIVE_CHECKBOX_AND_RADIO_FOCUS_OPACITY;
  border-radius: 50%;
}

@mixin checkbox-and-radio-label-text {
  font-family: var(--jive-font);
  margin-left: $JIVE_CHECKBOX_AND_RADIO_MARGIN;
  font-size: $JIVE_CHECKBOX_AND_RADIO_FONT_SIZE;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--jive-font-color);
}

// This is how google material hides inputs
@mixin hideInput {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
