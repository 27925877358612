@import "variables";

button.jive-primary {
  color: var(--jive-button-primary-font-color) !important;
  background-color: var(--jive-button-primary-color) !important;

  &:active:not([disabled]) {
    background-color: var(--jive-button-primary-color-pressed) !important;
    filter: var(--jive-button-primary-filter-pressed) !important;
    -webkit-filter: var(--jive-button-primary-filter-pressed) !important;
  }

  &:hover:not([disabled]) {
    background-color: var(--jive-button-primary-color-hover) !important;
  }
}

button.jive-button {
  &:disabled {
    color: var(--jive-button-font-color-disabled) !important;
    background-color: var(--jive-button-color-disabled) !important;
    filter: var(--jive-button-filter-disabled) !important;
    -webkit-filter: var(--jive-button-filter-disabled) !important;
  }
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--jive-primary-color) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--jive-primary-color-light) !important;
}
