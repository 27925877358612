@import './styles.scss';

@mixin jive-button-colors {
  background-color: var(--jive-button-color);
  color: var(--jive-button-font-color);
  svg { fill: var(--jive-button-font-color); }
  border: none;

  &:hover:not([disabled]) {
    background-color: var(--jive-button-color-hover);
  }

  &.jive-primary {
    background-color: var(--jive-primary-color);
    color: var(--jive-primary-font-color);
    svg { fill: var(--jive-primary-font-color); }
    border: none;

    &:hover:not([disabled]) {
      background-color: var(--jive-primary-color-hover);
    }
  }

  &.jive-warn {
    background-color: var(--jive-warn-color);
    color: var(--jive-warn-font-color);
    svg { fill: var(--jive-warn-font-color); }
    border: none;

    &:hover:not([disabled]) {
      background-color: var(--jive-warn-color-hover);
    }
  }

  &.jive-link, &.jive-flat {
    background-color: var(--jive-button-flat-color);
    color: var(--jive-button-flat-font-color);
    svg { fill: var(--jive-button-flat-font-color); }
    border: none;
  }

  &.jive-accent, &.jive-secondary {
    background-color: var(--jive-secondary-color);
    color: var(--jive-secondary-font-color);
    svg { fill: var(--jive-secondary-font-color); }
    border-color: var(--jive-secondary-border-color);
    border-style: solid;
    border-width: $JIVE_BUTTON_SECONDARY_BORDER_WIDTH;

    &:hover:not([disabled]) {
      background-color: var(--jive-secondary-color);
      color: var(--jive-secondary-font-color-hover);
      svg { fill: var(--jive-secondary-font-color-hover) }
      border-color: var(--jive-secondary-border-color-hover);
    }
  }
}

button, a {

  &.jive-button {
    @include jive-button();
    @include jive-button-colors;
    height: $JIVE_BUTTON_MIN_HEIGHT;

    &.jive-accent {
      &.jive-small, &.jive-medium { line-height: $JIVE_BUTTON_SECONDARY_SMALL_AND_MEDIUM_PADDING; }
    }

    &.jive-small {
      height: $JIVE_INPUT_COMPONENT_HEIGHT_SMALL;
      min-height: $JIVE_INPUT_COMPONENT_HEIGHT_SMALL;
      line-height: $JIVE_INPUT_COMPONENT_HEIGHT_SMALL;
      font-size: $JIVE_BUTTON_FONT_SIZE_SMALL;
    }

    &.jive-medium {
      min-height: $JIVE_INPUT_COMPONENT_HEIGHT_MEDIUM;
      height: $JIVE_INPUT_COMPONENT_HEIGHT_MEDIUM;
      line-height: $JIVE_INPUT_COMPONENT_HEIGHT_MEDIUM;
      font-size: $JIVE_BUTTON_FONT_SIZE_MEDIUM;
    }
  }

  &.jive-flat-button {
    @include jive-button();
    background-color: transparent;
    color: var(--jive-button-font-color);
    &.jive-primary { color: var(--jive-primary-color); }
    &.jive-warn { color: var(--jive-warn-color); }
    &.jive-link { color: var(--jive-button-flat-color); }
    &.jive-accent, &.jive-secondary { color: var(--jive-secondary-color); }
    height: $JIVE_BUTTON_MIN_HEIGHT;

    &:hover:not([disabled]) {
      background-color: var(--jive-button-color);
    }
  }

  &.jive-accent, &.jive-secondary {
    line-height: $JIVE_BUTTON_SECONDARY_LINE_HEIGHT;
    padding: $JIVE_BUTTON_SECONDARY_PADDING;
  }

  &.jive-fab {
    @include jive-button-fab;
    @include jive-button-colors;
  }

  &.jive-fab-mini {
    @include jive-button-mini-fab;
    @include jive-button-colors;
  }

}
