.flag {
  background: no-repeat url('^assets/images/flag.png');
  width: 16px;
  height: 14px;
  display: inline-block;
}

// nth column position is -20*(n-1)
// nth row position is -(20*(n-1)+ 1px)

.flag-en-US {
  background-position: -300px -201px;
}

.flag-es-MX {
  background-position: -100px -121px;
}

.flag-fr-CA, .flag-en-CA {
  background-position: -360px -21px;
}

.flag-en-GB {
  background-position: -260px -201px;
}

.flag-en-IE {
  background-position: -360px -81px;
}

.flag-de-AT {
  background-position: -180px -101px;
}

.flag-de-DE {
  background-position: -360px -61px;
}

.flag-pt-BR {
  background-position: -200px -21px;
}

.flag-en-AU {
  background-position: -340px -1px;
}

.flag-it-IT {
  background-position: -440px -81px;
}

.flag-fr-FR {
  background-position: -220px -61px;
}

.flag-es-ES {
  background-position: -120px -181px;
}
