@import '../shared.scss';

$JIVE_BUTTON_BOX_SHADOW: none;
$JIVE_BUTTON_BORDER_RADIUS: 3px;
$JIVE_BUTTON_MIN_WIDTH: 88px;
$JIVE_BUTTON_MIN_HEIGHT: $JIVE_INPUT_COMPONENT_HEIGHT;
$JIVE_BUTTON_BORDER: none;
$JIVE_BUTTON_LETTER_SPACING: 0.6px;
$JIVE_BUTTON_FONT_SIZE: $JIVE_FONT_SIZE;
$JIVE_BUTTON_FONT_SIZE_SMALL: $JIVE_FONT_SIZE_SMALL;
$JIVE_BUTTON_FONT_SIZE_MEDIUM: $JIVE_FONT_SIZE_MEDIUM;
$JIVE_BUTTON_TEXT_DECORATION: none;
$JIVE_BUTTON_TEXT_ALIGN: center;
$JIVE_BUTTON_LINE_HEIGHT: $JIVE_INPUT_COMPONENT_HEIGHT;
$JIVE_BUTTON_CURSOR: pointer;
$JIVE_BUTTON_DISABLED_CURSOR: not-allowed;
$JIVE_BUTTON_BOX_SIZING: border-box;
$JIVE_BUTTON_OUTLINE: none;
$JIVE_BUTTON_DISPLAY: inline-flex;
$JIVE_BUTTON_ALIGN_ITEMS: center;
$JIVE_BUTTON_JUSTIFY_CONTENT: center;
$JIVE_BUTTON_FONT_WEIGHT: 500;
$JIVE_BUTTON_MARGIN_BETWEEN_BUTTONS: 16px;
$JIVE_BUTTON_PADDING: 16px;
$JIVE_BUTTON_TRANSITION: 0.15s ease-in-out;

$JIVE_BUTTON_SECONDARY_BORDER_WIDTH: 2px;
$JIVE_BUTTON_SECONDARY_PADDING: 0px;
$JIVE_BUTTON_SECONDARY_LINE_HEIGHT: 33px;
$JIVE_BUTTON_SECONDARY_SMALL_AND_MEDIUM_PADDING: 20px;

$JIVE_BUTTON_FAB_BORDER_RADIUS: 50%;
$JIVE_BUTTON_FAB_MIN_HEIGHT: 56px;
$JIVE_BUTTON_FAB_MIN_WIDTH: 56px;
$JIVE_BUTTON_FAB_PADDING: 0px;
$JIVE_BUTTON_MINI_FAB_MIN_HEIGHT: 40px;
$JIVE_BUTTON_MINI_FAB_MIN_WIDTH: 40px;

@mixin jive-button (
  $MIN_HEIGHT: $JIVE_BUTTON_MIN_HEIGHT,
  $MIN_WIDTH: $JIVE_BUTTON_MIN_WIDTH,
  $BORDER_RADIUS: $JIVE_BUTTON_BORDER_RADIUS,
  $FONT_SIZE: $JIVE_BUTTON_FONT_SIZE,
  $PADDING: $JIVE_BUTTON_PADDING
) {
  box-shadow: $JIVE_BUTTON_BOX_SHADOW;
  border-radius: $BORDER_RADIUS;
  min-width: $MIN_WIDTH;
  min-height: $MIN_HEIGHT;
  border: $JIVE_BUTTON_BORDER;
  cursor: $JIVE_BUTTON_CURSOR;
  letter-spacing: $JIVE_BUTTON_LETTER_SPACING;
  box-sizing: $JIVE_BUTTON_BOX_SIZING;
  outline: $JIVE_BUTTON_OUTLINE;
  display: $JIVE_BUTTON_DISPLAY;
  align-items: $JIVE_BUTTON_ALIGN_ITEMS;
  justify-content: $JIVE_BUTTON_JUSTIFY_CONTENT;
  font-family: var(--jive-font);
  font-size: $FONT_SIZE;
  font-weight: $JIVE_BUTTON_FONT_WEIGHT;
  text-transform: uppercase;
  text-decoration: $JIVE_BUTTON_TEXT_DECORATION;
  text-align: $JIVE_BUTTON_TEXT_ALIGN;
  line-height: $JIVE_BUTTON_LINE_HEIGHT;
  padding: 0px $PADDING;
  transition: background-color $JIVE_BUTTON_TRANSITION, color $JIVE_BUTTON_TRANSITION;

  &:active {
    outline: $JIVE_BUTTON_OUTLINE;
    box-shadow: $JIVE_BUTTON_BOX_SHADOW;
    filter: brightness(85%);
  }

  &:disabled {
    cursor: $JIVE_BUTTON_DISABLED_CURSOR;
    filter: opacity(50%);
  }
}

@mixin jive-button-fab {
  @include jive-button(
    $JIVE_BUTTON_FAB_MIN_HEIGHT,
    $JIVE_BUTTON_FAB_MIN_WIDTH,
    $JIVE_BUTTON_FAB_BORDER_RADIUS,
    $JIVE_BUTTON_FONT_SIZE_MEDIUM,
    $JIVE_BUTTON_FAB_PADDING
  );
  max-width: $JIVE_BUTTON_FAB_MIN_WIDTH;
  max-height: $JIVE_BUTTON_FAB_MIN_HEIGHT;
}

@mixin jive-button-mini-fab {
  @include jive-button(
    $JIVE_BUTTON_MINI_FAB_MIN_HEIGHT,
    $JIVE_BUTTON_MINI_FAB_MIN_WIDTH,
    $JIVE_BUTTON_FAB_BORDER_RADIUS,
    $JIVE_BUTTON_FONT_SIZE_SMALL,
    $JIVE_BUTTON_FAB_PADDING
  );
  max-width: $JIVE_BUTTON_MINI_FAB_MIN_WIDTH;
  max-height: $JIVE_BUTTON_MINI_FAB_MIN_HEIGHT;
}
