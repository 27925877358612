@import "variables";
@import "../style/buttons/button";
@import '../style/inputs/styles';

.error-page-title {
  color: $md-grey-800;
  font-weight: $semibold-font;
}

.component-title {
  color: var(--jive-primary-color);
  font-size: 20px;
  font-weight: 300;
}

.page-title {
  margin: 20px 32px 0;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.page-title-text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: -10px;
}

.input-title {
  @include jive-input-header;
}

.bp-modal {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  align-items: center;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.bp-modal-content {
  position: absolute;
  background-color: white;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  width: 250px;
  padding: 32px 32px;
  @include breakpoint(desktop) {
    max-height: calc(100vh - 210px);
    width: 366px;
    padding: 32px 48px;
  }
}

.url-link {
  color: var(--jive-primary-color);
  text-decoration: none;
}

// animation for ellipsis when loading
.loading {
  font-size: 20px;
  font-style: italic;
}

.loading:after {
  padding-left: 3px;
  font-weight: 800;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1000ms infinite;
  animation: ellipsis steps(4, end) 1000ms infinite;
  content: ". . .";
  width: 0;
  white-space: nowrap;
}

@keyframes ellipsis {
  to {
    width: 1.7em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.7em;
  }
}

// Cardinal iFrame size
#Cardinal-Modal #Cardinal-ModalContent,
#Cardinal-Modal {
  height: 440px
}

#Cardinal-CCA-IFrame {
  height: 430px;
}
