@use 'material-colors/dist/colors';
@import "@angular/material/theming";

/* Grid Breakpoints */
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 600px) { @content ; }
  } @else if $point == mobile {
    @media (max-width: 599px) { @content ; }

  } @else if $point == largeDesktopForInvoice {
    @media (min-width: 1100px) { @content; }
  } @else if $point == largeDesktop {
    @media (min-width: 1700px) { @content; }
  }
}

// A mixin to help control mat-icon sizess
@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

@mixin money-format {
  white-space: nowrap;
}

$bp-error-highlight: #ffa000;
$bp-super-light-grey: colors.$md-grey-300;
$bp-light-grey: colors.$md-grey-600;
$bp-error-message-text: colors.$md-red-700;
$bp-error-form-boarder: colors.$md-red-700;
$bp-data-error-background: rgba(0, 0, 0, 0.9);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$jive-app-primary: mat-palette($mat-light-blue, A700);
$jive-app-accent: mat-palette($mat-teal, A200, A100, A400);

// The warn palette is optional (defaults to red).
$jive-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$jive-app-theme: mat-light-theme($jive-app-primary, $jive-app-accent, $jive-app-warn);
$jive-primary-colors: map-get($jive-app-theme, primary);

$sans-serif: "Proxima Nova", "proxima-nova", "Open Sans", "Helvetica", sans-serif;
$light-font: 200;
$semibold-font: 400;

// Additional variables beyond the material theme
// Note: Only use colors from the material theme here
$default-border-color: colors.$md-grey-300;
$default-border: 1px solid $default-border-color;
$default-border-radius: 3px;

$default-text-color: colors.$md-grey-600;
$dark-text-color: colors.$md-grey-800;
$light-text-color: get-color-from-palette(colors.$md-grey-100, "A100");

@mixin absolute-horizontal-centering($width) {
  position: absolute;
  width: $width;
  left: calc(50% - (#{$width} / 2));
}
